/*
Theme Name: Schaltbau
Theme URI: https://www.schaltbau.com
Author: Edelman.ergo, INTO Branding
Author URI: http://www.edelmanergo.com/
Description: This is a custom theme drafted and developed for Schaltbau Group.
Version: 1.0
Text Domain: schaltbau

This theme, is licensed to Schaltbau Group.
*/
/**
 * Table of Contents
 *
 * 1.0 - Webfonts
 * 2.0 - Normalize
 * 3.0 - Grid (Toast Grid)
 * 4.0 - Typography
 * 5.0 - Globals
 * 6.0 - Forms
 * 	  6.1 - Top search
 * 	  6.2 - Mobile search
 * 	  6.3 - News search
 * 7.0 - Navigation
 * 	  7.1 - Hauptnavigation
 * 	  7.2 - Topnavigation
 * 	  	  7.3.a - Sprachenswitcher (Top navigation)
 * 	  	  7.3.b - Sprachenswitcher (Mobile navigation)
 * 	  7.4 - Footernavigation
 * 	  7.5 - Mobile navigation
 * 8.0 - Elements
 * 9.0 - Widgets (Content)
 * 	  9.1 - Teaser 1 (Headline, Image, Text, Button)
 * 	  9.2 - Product search
 * 10.0 - Widgets (Sidebar)
 * 	  10.1 - Latest posts
 * 10.0 - Content
 * 	  10.1 - Top bar
 *    10.2 - Header
 * 	  10.3 - Top images
 * 	  10.4 - Posts and pages
 *    10.5 - Footer
 * 10.0 - References
 * 11.0 - Media Queries
 * 	  11.1 - <= 1100px
 * 	  11.2 - <= 900px
 * 	  11.3 - <= 700px
 * 	  11.4 - <= 480px
 * 12.0 - Print
 */
/** 0.0 - Webfont*/
@import 'inc/variables';
/** 1.0 - Webfont*/
@import 'inc/fonts';
/** .0 - Normalize */
@import 'inc/normalize';
/** 3.0 - Grid (Toast Grid) */
@import 'inc/grid';

.grid {
	margin-left: -30px;
}

.grid__col {
	padding-left: 30px;
}

.grid__col--1-of-1 {
	width: 100%;
}

.grid__col--push-1-of-1 {
	margin-left: 100%;
}

.grid__col--pull-1-of-1 {
	margin-left: -100%;
}

@media (max-width: 700px) and (min-width: 480px) {
	.grid__col.grid__col--m-1-of-1 {
		width: 100%;
	}
}

@media (max-width: 480px) {
	.grid__col.grid__col--s-1-of-1 {
		width: 100%;
	}
}


/** 4.0 - Typography */
body, button, input, select, textarea {
	color: $body-color;
	font-family: $font-family-light;
	font-size: 16px;
	line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
	clear: both;
	font-family: $font-family-light;
	font-size: 24px;
	line-height: 28px;
	font-weight: normal;
	margin: 0;
	text-rendering: optimizeLegibility;

	&.centered {
		text-align: center;
	}
}

.site-content {
	h1, h2, h3, h4, h5, h6 {
		margin: 20px 0;
	}
}

h1 {
	margin-bottom: 30px;
	color: $theme;
	font-size: 32px;
	line-height: 36px;
}

.headline {
	margin-bottom: 20px;
	color: $mid-gray;

	&.underline {
		border-bottom: 1px solid $light-gray;
		padding-bottom: 15px;
	}
}

.bt-0 {
	border-top: 0 !important;
}

.small-black-headline,
.site-content h5.small-black-headline {
	margin-bottom: 10px;
	color: $dark-gray;
	font-family: "BrixSansMedium", "Helvetica Neue", Arial, serif;
	font-size: 17px;
	line-height: 20px;
}

.red-phone-number {
	padding: 5px;
	margin-bottom: 20px;
	border: 1px solid #dedede;
	background-color: #efefef;
	color: $theme;
	font-family: "BrixSansMedium", "Helvetica Neue", Arial, serif;
	font-size: 17px;
	line-height: 20px;
	text-align: center;
}

.site-content .micro-headline,
.site-content .small-headline,
.site-content .middle-headline,
.site-content .big-headline {
	margin-bottom: 30px;
	color: $theme;
	font-family: $font-family-light;
	line-height: 1;
}

.site-content .micro-headline.bold,
.site-content .small-headline.bold,
.site-content .middle-headline.bold,
.site-content .big-headline.bold {
	font-family: "BrixSansBlack", "Helvetica Neue", Arial, serif;
}

.site-content .micro-headline {
	font-size: 17px;
	margin-bottom: 10px;
}

.site-content .small-headline {
	font-size: 24px;
	margin-bottom: 15px;
}

.site-content .middle-headline {
	font-size: 32px;
	margin-bottom: 20px;
}

.site-content .big-headline {
	font-size: 48px;
}

.site-content .micro-headline.above,
.site-content .small-headline.above,
.site-content .middle-headline.above,
.site-content .big-headline.above,
.site-content .micro-headline.below,
.site-content .small-headline.below,
.site-content .middle-headline.below,
.site-content .big-headline.below {
	border-color: $light-gray;
	border-style: solid;
	border-width: 0;
}

.site-content .micro-headline.above,
.site-content .small-headline.above,
.site-content .middle-headline.above,
.site-content .big-headline.above {
	border-top-width: 1px;
	padding-top: 10px;
}

.site-content .micro-headline.below,
.site-content .small-headline.below,
.site-content .middle-headline.below,
.site-content .big-headline.below {
	border-bottom-width: 1px;
}

p {
	margin: 0 0 20px;
}

.red {
	color: $theme;
}

.link {
	display: inline-block;
	position: relative;
	text-decoration: underline;
	padding-left: 10px;
	line-height: 1.6;
	color: #ee1c25;
}

.link:before {
	position: absolute;
	display: inline-block;
	top: 5px;
	left: 0;
	content: '\f104';
	font-family: "Genericons";
	font-size: 9px;
	text-decoration: none !important;
}

.link.newline {
	display: block;
	margin-top: 10px;
}

.link.noicon {
	padding-left: 0;
}

.link.noicon:before {
	content: '';
	display: none;
}

.intro_paragraph {
	margin-bottom: 30px;
	color: $mid-gray;
	font-size: 17px;
}


/** 5.0 - Globals */
html {
	box-sizing: border-box;
	height: 100%;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	background: #fff;
	margin: 0;
	height: 100%;
}

iframe {
	width: 100%;
}

a, a:after {
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
}

a {
	color: $theme;
	text-decoration: none;
}

a:focus {
	outline: thin dotted;
}

a:hover,
a:active {
	color: $mid-gray;
}

footer .widget_nav_menu .menu > .menu-item > a:hover,
footer .widget_nav_menu .sub-menu > .menu-item > a:hover {
	color: $theme-alt;
}

hr {
	background-color: $light-gray;
	border: 0;
	height: 1px;
	margin: 0 0 20px;
}

.main-content.lightgrey hr,
ul.block-list hr {
	background-color: #ccc;
}

ul,
ol {
	margin: 0 0 20px 20px;
	padding: 0;
}

ul {
	list-style: disc;
}

li > ul,
li > ol {
	margin-bottom: 0;
}

img {
	display: block;
	height: auto;
	max-width: 100%;
	vertical-align: middle;
}

caption,
th,
td {
	font-weight: normal;
}


/**
 * 6.0 - Forms
 */

input {
	line-height: normal;
}

button,
button[disabled]:hover,
button[disabled]:focus,
input[type="button"],
input[type="button"][disabled]:hover,
input[type="button"][disabled]:focus,
input[type="reset"],
input[type="reset"][disabled]:hover,
input[type="reset"][disabled]:focus,
input[type="submit"],
input[type="submit"][disabled]:hover,
input[type="submit"][disabled]:focus {
	background-color: $theme;
	border: 1px solid $theme;
	padding: 8px 15px;
	color: #fff;
	line-height: 1;

	-webkit-transition: background-color 0.5s;
	transition: background-color 0.5s;
}

button:hover,
button:focus,
input[type="button"]:hover,
input[type="button"]:focus,
input[type="reset"]:hover,
input[type="reset"]:focus,
input[type="submit"]:hover,
input[type="submit"]:focus {
	background-color: $mid-gray;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="file"],
input[type="number"],
textarea {
	background-color: #fff;
	border: none;
	width: 100%;
	height: 40px;
	padding: 10px 15px 10px 15px;
	color: $dark-gray;
	font-size: 16px;

	-webkit-transition: background-color 0.2s;
	transition: background-color 0.2s;

	-webkit-appearance: none;
	-webkit-border-radius: 0px;
	border-radius: 0;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus {
	border: 1px solid #ccc;
	outline: none;
}

select {
	position: relative;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	border-radius: 0;
	width: 100%;
	height: 40px;
	padding: 10px 35px 10px 15px;
	background-color: $mid-gray;
	background-image: url(../img/dropdown-arrow.svg);
	background-position: right 15px center;
	background-repeat: no-repeat;
	background-size: 12px auto;
	color: #fff;
}

select:focus, input[type="file"]:focus {
	outline: none;
}

input[type="file"] {
	//position: relative;
	appearance: none;
	border: none;
	border-radius: 0;
	width: 100%;
	height: auto;
	padding: 10px 35px 10px 15px;
	background-color: $mid-gray;
	background-position: right 15px center;
	color: #fff;
}


/**
 * 6.1 - Top search
 */

.top-search {
	position: relative;
	float: right;
}

.top-search input[type="search"] {
	display: block;
	border: 0;
	background: transparent;
	height: 20px;
	width: 90px;
	padding: 0;
	color: #fff;
	font-size: 13px;
	float: left;
}

.top-search input[type="search"]::-webkit-input-placeholder {
	color: #fff;
}

.top-search input[type="search"]:focus::-webkit-input-placeholder {
	color: transparent;
}

.top-search button[type="submit"] {
	background: none;
	float: left;
	width: 15px;
	padding: 0;
	font-size: 16px;
	line-height: 1.5;
}


/**
 * 6.2 - Mobile search
 */

.mobile-search {
	position: relative;
	overflow: hidden;
}

.mobile-search input[type="search"] {
	display: block;
	border: 0;
	background-color: #ddd;
	height: 40px;
	width: 85%;
	padding: 0 20px;
	color: $dark-gray;
	font-size: 16px;
	float: left;

	-webkit-transition: background-color 0.1s;
	transition: background-color 0.1s;
}

.mobile-search input[type="search"]:focus {
	background-color: #fff;
}

.mobile-search button[type="submit"] {
	background: none;
	float: left;
	height: 40px;
	width: 15%;
	padding: 0;
	font-size: 18px;
	line-height: 1.5;

	-webkit-transition: background-color 0.1s;
	transition: background-color 0.1s;
}

.mobile-search button[type="submit"]:hover {
	background-color: $mid-gray;
}


/**
 * 6.4 - Search form (Globals)
 */

.search-content:after {
	display: block;
	content: '';
	height: 1px;
	clear: both;
}

.advanced-search:after {
	display: block;
	content: '';
	height: 1px;
	clear: both;
}

.advanced-search ul.advanced-search-list .sub-dropdown-label {
	height: 40px;
}


/**
 * 6.5 - Product search
 */

.product-search-content .advanced-search {
	margin-bottom: 0;
}

.product-search-content ul.advanced-search-list {
	float: left;
	width: 49%;
}

.product-search-content ul.advanced-search-list.product_cat {
	margin-right: 2%;
}


/**
 * 6.5 - News search
 */

.news-search-content .search-form {
	position: relative;
	display: block;
	float: left;
	width: 70%;
}

.news-search-content .search-form > label {
	display: block;
	float: left;
	width: 70%;
}

.news-search-content .search-form > .search-submit {
	display: block;
	float: left;
	width: 20%;
	margin-left: 2%;
	height: 40px;
}

.news-search-content .search-form .dgwt-wcas-ico-loupe {
	height: 50%;
	left: 12px;
	right: auto;
	opacity: 0.5;
	fill: #000;
}

.news-search-content .advanced-search {
	display: block;
	float: left;
	width: 30%;
}

.news-search-content .search-form input[type="search"] {
	display: block;
	border: 1px solid #ddd;
	background-color: #fff;
	height: 40px;
	padding: 10px 15px 10px 40px;
	color: $dark-gray;
	font-size: 16px;

	-webkit-transition: background-color 0.1s;
	transition: background-color 0.1s;
}


/**
 * 6.6 - Contact form
 */

.wpforms-container .wpforms-field .wpforms-field-label {
	float: left;
	width: 30%;
}

.wpforms-container .wpforms-field-name {
	overflow: hidden;
}

.wpforms-container .wpforms-field .wpforms-field-large {
	width: 70%;
}

.wpforms-container .wpforms-field-select .wpforms-field-large {
	width: 30%;
}

.wpforms-container .wpforms-field-description {
	margin-left: 30% !important;
}

.wpforms-container .wpforms-field .wpforms-field-row {
	float: left;
}

.wpforms-recaptcha-container {
	min-height: 80px;
	width: 70%;
	margin-left: 30%;
}

.wpforms-submit {
	padding: 10px 15px 10px 15px;
}

.wpforms-error-container {
	margin-bottom: 15px;

	padding: 15px;
	box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
	background-color: #ffe9e9;
	border: 1px solid #fbc4c4;
	color: $theme !important;
}

.wpforms-container label.wpforms-error {
	margin-bottom: 5px;
	padding: 6px 15px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
	background-color: #ffe9e9;
	border: 1px solid #fbc4c4;
	color: $theme !important;
}

.wpforms-container .wpforms-field > label.wpforms-error {
	width: 70%;
	margin-left: 30%;
}

.wpforms-container .wpforms-field input.wpforms-error, .wpforms-container .wpforms-field textarea.wpforms-error, .wpforms-container .wpforms-field select.wpforms-error {
	border: 1px solid $theme;
}

.g-recaptcha {
	margin-bottom: 15px;
}


/**
 * 7.0 - Navigation
 */


/**
 * 7.1 - Hauptnavigation
 */

#main-navigation {
	display: block;
	list-style: none;
	margin: 36px 0 0 0;
	height: 60px;
	float: right;
}

#main-navigation .menu-item {
	float: left;
	margin-left: 40px;
	padding-top: 15px;
}

#main-navigation .menu-item a {
	display: block;
	padding-bottom: 15px;
	color: $mid-gray;
	font-size: 18px;
	border-bottom: 3px solid #fff;
}

#main-navigation .menu-item a:hover {
	color: $theme;
}

#main-navigation .current-menu-item a,
#main-navigation .current-page-ancestor a,
#main-navigation .current_page_parent a {
	border-color: $theme;
}


/**
 * 7.2 - Topnavigation
 */

#top-navigation {
	list-style: none;
	float: right;
}

ul#top-navigation {
	margin-left: 0;
}

#top-navigation .menu-item {
	float: left;
	padding-left: 20px;
	text-align: right;
	box-sizing: border-box;
}

#top-navigation .menu-item a {
	display: block;
	color: #fff;
	font-size: 14px;
	line-height: 1.2;
}

#top-navigation .menu-item a:hover {
	color: #fabdb4;
}


/**
 * 7.3.a - Sprachenswitcher (Top navigation)
 */

#top-navigation .menu-item-language-current {
	position: relative;
}

#top-navigation .menu-item-language-current > a:before {
	display: inline-block;
	content: '\f431';
	margin-right: 5px;
	font-family: "Genericons";
	font-size: 12px;
}

#top-navigation .menu-item-language-current .submenu-languages {
	display: none;
	position: absolute;
	top: 22px;
	right: 0;
	background-color: #770f00;
	padding: 8px 20px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .4);
	list-style: none;
}

#top-navigation .menu-item-language-current.open .submenu-languages {
	display: block;
}

#top-navigation .menu-item-language-current .submenu-languages .menu-item-language {
	padding: 2px 0;
	text-align: left;
}

#top-navigation .menu-item-language-current .submenu-languages .menu-item-language a {
	display: block;
	color: #fff;
	font-size: 13px;
	font-weight: normal;
	text-decoration: none;
}

#top-navigation .menu-item-language-current .submenu-languages .menu-item-language a:hover {
	color: #fabdb4;
}


/**
 * 7.3.b - Sprachenswitcher (Mobile navigation)
 */

#mobile-navigation .menu-item.menu-item-language-current {
	height: 45px;
	max-height: none;
	padding-left: 20px;
}

#mobile-navigation .menu-item-language-current a,
#mobile-navigation .menu-item-language-current .sub-menu .menu-item a {
	font-size: 16px;
	line-height: 45px;
}

#mobile-navigation .menu-item-language-current > a {
	color: #fff;
}

#mobile-navigation .menu-item-language-current .sub-menu,
#mobile-navigation .menu-item-language-current .sub-menu .menu-item,
#mobile-navigation .menu-item-language-current .sub-menu .menu-item a {
	display: inline;

	margin: 0;
	padding: 0;
	background: no-repeat;
}

#mobile-navigation .menu-item-language-current .sub-menu .menu-item {
	position: relative;
	margin-left: 10px;
	padding-left: 10px;
}

#mobile-navigation .menu-item-language-current .sub-menu .menu-item:before {
	position: absolute;
	display: inline-block;
	top: 4px;
	left: 0;
	content: "";
	height: 10px;
	border-left: 1px solid #6a6a6a;
}

#mobile-navigation .menu-item-language-current .sub-menu .menu-item a:hover {
	color: #fff;
}


/**
 * 7.4 - Footernavigation
 */

footer .widget_nav_menu ul.menu,
footer .widget_nav_menu ul.sub-menu {
	list-style: none;
	margin-left: 0;
}

footer .widget_nav_menu .widget-title {
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 2px solid $mid-gray;
	color: $mid-gray;
	font-family: $font-family-light;
	font-size: 16px;
	font-weight: normal;
}

footer .widget_nav_menu .menu > .menu-item {
	margin-bottom: 5px;
}

footer .widget_nav_menu .menu > .menu-item > a {
	display: block;
	color: #fff;
	font-size: 15px;
	line-height: 18px;
}

footer .widget_nav_menu .menu > .menu-item:first-child > a {
	/*height: 50px;*/
}

footer .widget_nav_menu .sub-menu {
	margin-top: 20px;
}

footer .widget_nav_menu .sub-menu > .menu-item {
	margin-bottom: 5px;
}

footer .widget_nav_menu .sub-menu > .menu-item > a {
	display: block;
	color: $mid-gray;
	font-size: 13px;
	line-height: 14px;
}


/**
 * 7.5 - Mobile navigation
 */

#toggle-mobile-menu {
	position: absolute;
	display: none;
	top: 0;
	right: 0;
}

#toggle-mobile-menu:before {
	display: block;
	font-family: "Genericons";
	content: '\f419';
	height: 85px;
	width: 85px;
	color: $mid-gray;
	font-size: 32px;
	line-height: 80px;
	text-align: center;
	cursor: pointer;
}

body.openmenu #toggle-mobile-menu:before {
	content: '\f406';
}

#mobile-navigation {
	position: fixed;
	z-index: 333;
	top: 0;
	left: 100%;
	height: 100%;
	width: 240px;
	background-color: $dark-gray;
	text-align: left;

	overflow-x: visible;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	-webkit-transition: -webkit-transform 0.5s;
	-ms-transition: transform 0.5s;
	transition: transform 0.5s;

	-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

body.openmenu #mobile-navigation {

	-webkit-transform: translate3d(-240px, 0, 0);
	-ms-transform: translate3d(-240px, 0, 0);
	transform: translate3d(-240px, 0, 0);
}

#mobile-navigation ul.menu,
#mobile-navigation ul.sub-menu {
	list-style: none;
	margin-left: 0;
}

#mobile-navigation .menu-item {
	position: relative;
	display: block;
	max-height: 35px;
	overflow: hidden;
	-webkit-transition: max-height 0.6s ease;
	transition: max-height 0.6s ease;
}

#mobile-navigation .sub-menu .menu-item {
	max-height: 32px;
}

#mobile-navigation .menu-item.open,
#mobile-navigation .sub-menu .menu-item.open {
	height: auto;
}

#mobile-navigation .menu > .menu-item > a,
#mobile-navigation .sub-menu > .menu-item > a {
	display: block;
	background-color: #393939;
	margin-top: 1px;
	padding: 7px 0 7px 20px;
	color: #fff;
	font-size: 16px;

	-webkit-transition: background-color 0.1s, color 0.1s;
	transition: background-color 0.1s, color 0.1s;
}

#mobile-navigation .sub-menu > .menu-item > a {
	background-color: #3d3d3d;
	color: #999;
	font-size: 12px;
}

#mobile-navigation .sub-menu .sub-menu > .menu-item > a {
	background-color: #434343;
	padding-left: 30px;
}

#mobile-navigation .menu > .menu-item-has-children > i:after,
#mobile-navigation .sub-menu > .menu-item-has-children > i:after {
	position: absolute;
	display: block;
	font-family: "Genericons";
	content: '\f502';
	background-color: #393939;
	border-left: 1px solid $dark-gray;
	top: 1px;
	right: 0;
	width: 40px;
	height: 35px;
	color: #fff;
	font-size: 12px;
	font-style: normal;
	text-align: center;
	line-height: 3.6;
}

#mobile-navigation .sub-menu > .menu-item-has-children > i:after {
	content: '\f431';
	background-color: #3d3d3d;
	height: 32px;
	font-size: 18px;
	line-height: 1.6;
}

#mobile-navigation .menu > .menu-item-has-children.open > i:after {
	content: '\f500';
	line-height: 2.5;
	color: #e63329;
}

#mobile-navigation .sub-menu > .menu-item-has-children.open > i:after {
	content: '\f432';
	color: #e63329;
}

#mobile-navigation .menu > .menu-item-has-children > i:hover:after,
#mobile-navigation .sub-menu > .menu-item-has-children > i:hover:after {
	cursor: pointer;
	background-color: $dark-gray;
	color: #e63329;
}

#mobile-navigation .menu > .menu-item > a:hover,
#mobile-navigation .sub-menu > .menu-item > a:hover,
#mobile-navigation .sub-menu .sub-menu > .menu-item > a:hover {
	background-color: $dark-gray;
	color: #e63329;
}


/**
 * 7.6 - List pagination
 */

.paginate {
	margin: 50px 0 40px 0;
	text-align: center;
}

.paginate .emm-page,
.paginate .emm-gap {
	display: inline-block;
	margin-right: 1px;
	width: 30px;
	height: 30px;
	color: #fff;
	text-align: center;
	line-height: 30px;
}

.paginate a.emm-page {
	background-color: $theme;

	-webkit-transition: background-color 0.2s;
	transition: background-color 0.2s;
}

.paginate a.emm-page:hover {
	background-color: $mid-gray;
}

.paginate .emm-page.emm-current {
	background-color: #e9e9e9;
	color: $dark-gray;
	cursor: default;
}

.paginate .emm-gap {
	background-color: #e9e9e9;
	color: $dark-gray;
	cursor: default;
}

.paginate .emm-next,
.paginate .emm-prev {
	position: relative;
	display: inline-block;
	margin-right: 1px;
	width: 30px;
	height: 30px;
	background-color: #fff;
	color: $mid-gray;
	text-indent: 35px;
	line-height: 30px;
	overflow: hidden;
	vertical-align: bottom;
}

.paginate .emm-next,
.paginate .emm-prev {
	color: $mid-gray;

	-webkit-transition: color 0.2s;
	transition: color 0.2s;
}

.paginate a.emm-next:hover,
.paginate a.emm-prev:hover {
	color: #000;
}

.paginate .emm-next:after,
.paginate .emm-prev:after {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	font-family: "Genericons";
	content: '\f429';
	font-size: 18px;
	text-align: right;
	text-indent: 0;
}

.paginate .emm-prev:after {
	content: '\f430';
	text-align: left;
}


/**
 * 8.0 - Elements
 */

.darkenarea {
	position: fixed;
	top: 0;
	z-index: 332;
	width: 100%;
	height: 100%;
	background: rgb(0, 0, 0);
	background: rgba(0, 0, 0, 0.60);
	display: none;
}

.openmenu .darkenarea {
	display: block;
}

.button,
.button-outline {
	display: inline-block;
	padding: 8px 15px;
	line-height: 1;
	border: 1px solid $theme;
}

.button {
	background-color: $theme;
	color: #fff;
	text-align: center;

	-webkit-transition: background-color 0.5s;
	transition: background-color 0.5s;
}

.button:hover {
	background-color: $mid-gray;
	color: #fff;
}

.button-outline {
	border: 1px solid $theme;
	text-align: center;

	-webkit-transition: border-color 0.3s, color 0.3s;
	transition: border-color 0.3s, color 0.3s;
}

.button-outline:hover {
	border-color: #999;
}

.grey-bg {
	background-color: #f6f6f6;
}

.blue-bg {
	background-color: $light-blue !important;
}

.violet-bg {
	background-color: #500039;
}

.custom-file-input::-webkit-file-upload-button {
	visibility: hidden;
}

.custom-file-input::before {
	content: 'Datei wählen';
	display: inline-block;
	border: none;
	outline: none;
	white-space: nowrap;
	cursor: pointer;
	background-color: $theme;
	border: 0;
	padding: 8px 15px;
	color: #fff;
	line-height: 1;

	-webkit-transition: background-color 0.5s;
	transition: background-color 0.5s;
}

.lang-en .custom-file-input::before {
	content: 'Select file';
}

.custom-file-input:hover::before {
	border-color: black;
}

.custom-file-input:active::before {
	background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

form .required {
	color: #ff0000;
    font-weight: normal;
}

.reset-form {
	ul {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	#id_new_password2 {
		margin-bottom: 1rem;
	}
}

/**
 * 8.1 - DropDown
 */

/* resets */
ul.dropdown-list,
ul.sub-dropdown-list {
	list-style: none;
	margin: 0;
	color: #fff;
}

ul.dropdown-list {
	position: relative;
	margin: 0;
	float: left;
	width: 100%;
	background-color: #6d6e70;
}

ul.dropdown-list li.sub-dropdown-label {
	position: relative;
	cursor: pointer;
	padding: 9px 25px 11px 13px;

	-webkit-transition: -webkit-transform 0.2s ease-in-out;
	transition: 0.2s ease-in-out;
}

ul.dropdown-list li.sub-dropdown-label:hover {
	background-color: $mid-gray;
}

ul.dropdown-list li.sub-dropdown-label:after {
	position: absolute;
	display: block;
	content: 'x';
	right: 20px;
	top: 50%;
	height: 20px;
	margin-top: -10px;
	color: #fff;
	font-size: 12px;
	line-height: 20px;
}

ul.dropdown-list.active li.sub-dropdown-label {
	background-color: $theme;
}

ul.dropdown-list.active li.sub-dropdown-label:after {
	content: '';
	display: none;
}

ul.dropdown-list li.sub-dropdown-label .close {
	position: absolute;
	display: none;
	top: 0;
	right: 0;
	width: 54px;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	font-style: normal;
	text-indent: 100px;
	overflow: hidden;
}

ul.dropdown-list li.sub-dropdown-label .close:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

ul.dropdown-list li.sub-dropdown-label .close:after {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: '\f405';
	color: #fff;
	font-family: "Genericons";
	font-size: 24px;
	text-align: center;
	line-height: 44px;
	text-indent: 0;
}

ul.dropdown-list.active li.sub-dropdown-label .close {
	display: block;
}

ul.dropdown-list li.sub-dropdown-group {
	position: absolute;
	display: block;
	z-index: 2;
	left: 0;
	background: transparent;
	width: 100%;
	min-height: 600px;
	margin-top: 1px;
	overflow: hidden;
	pointer-events: none;
}

ul.dropdown-list li.sub-dropdown-group ul.sub-dropdown-list {
	position: absolute;
	left: 0;
	width: 100%;
	padding: 0;
	border-left: 1px solid #dfdfdf;
	border-right: 1px solid #dfdfdf;
	background-color: #fff;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, .15);
	opacity: 0;

	-webkit-transition: -webkit-transform 0.2s ease-in-out;
	transition: 0.2s ease-in-out;

	-webkit-transform: translate3d(0, -101%, 0);
	-ms-transform: translate3d(0, -101%, 0);
	transform: translate3d(0, -101%, 0);
}

ul.dropdown-list.show li.sub-dropdown-group ul.sub-dropdown-list {
	opacity: 1;

	-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

}

ul.dropdown-list li.sub-dropdown-group ul.sub-dropdown-list li.sub-dropdown {
	display: block;
	margin: 0;
	width: 100%;
	background-color: #fff;
	border-bottom: 1px solid #dfdfdf;
	padding: 6px 25px 8px 13px;
	cursor: pointer;
	color: $dark-gray;
	pointer-events: auto;


	-webkit-transition: -webkit-transform 0.1s ease-in-out;
	transition: 0.1s ease-in-out;
}

ul.dropdown-list li.sub-dropdown-group ul.sub-dropdown-list li.sub-dropdown:hover {
	background-color: #e9e9e9;
}

ul.dropdown-list li.sub-dropdown-group ul.sub-dropdown-list li.sub-dropdown.selected {
	background-color: #e9e9e9;
}


/**
 * 9.0 - Widgets (Content)
 */

.widget .widget-title {
	display: block;
	margin: 0 0 15px;
	color: #e30613;
	font-size: 24px;
	line-height: 1.2;
}

.widget .widget-title:empty {
	margin-bottom: 0;
}

.widget .widget-image {
	margin-bottom: 15px;
}

.widget p > a {
	position: relative;
	padding-left: 10px;
	text-decoration: underline;
}

.widget p > a:after {
	position: absolute;
	content: '\f104';
	top: 5px;
	left: 0;
	font-family: "Genericons";
	font-size: 7px;
}

.widget p > a.button {
	padding-left: 15px;
	text-decoration: none;
}

.widget p > a.button:after {
	display: none;
	content: '';
}


/**
 * 9.1 - Teaser 1 (Headline, Image, Text, Button)
 */

.widget-1 {
	position: relative;
	display: block;
	padding: 0;
}

a.widget-1 {
	cursor: pointer;
}

.widget-1 > .widget-title {
	margin-top: 15px;
	padding-right: 15%;
}

.widget-1 > .widget-title.line-1 {
	height: 28px;
}

.widget-1 > .widget-title.line-2 {
	height: 56px;
}

.widget-1 > .widget-title.line-3 {
	height: 84px;
}

.widget-1.bild_none > .widget-title {

}

.widget-1 .widget-1-inner {
	padding-right: 5%;
	color: $mid-gray;
}

a.widget-1:hover .widget-1-inner {
	color: #e30613;
}

.widget-1.divider:before {
	position: absolute;
	display: block;
	content: "";
	top: 0;
	width: 1px;
	height: 100%;
	background-color: $light-gray;
}

.main-content.lightgrey .widget-1.divider:before,
.tabs-content .widget-1.divider:before {
	background-color: #ccc;
}

.widget-1.divider.right:before {
	right: -15px;
}

.widget-1.divider.left:before {
	left: -15px;
}

.widget-1.divider.bottom:before,
.widget-1.divider.top:before {
	width: 100%;
	height: 1px;
	top: auto;
	left: 0;
}

.widget-1.divider.bottom:before {
	bottom: -15px;
}

.widget-1.divider.bottom {
	margin-bottom: 50px;
}

.widget-1.divider.top:before {
	top: -15px;
}

.widget-1 .widget-1-inner .button-outline {
	color: $theme;
	border-color: $theme;
}

.widget-1.bild_links .widget-image {
	float: left;
	width: 30%;
	margin-top: 5px;
}

.products .widget-1.bild_links .widget-image {
	width: 20%;
}

.widget-1.bild_links .widget-1-inner {
	float: left;
	width: 60%;
	min-height: 150px;
	margin-left: 5%;
	line-height: 1.4;
}

.products-cat .widget-1 .widget-1-inner {
	font-size: 16px;
}

.products-cat .widget-1 .widget-1-inner .link {
	font-size: 16px;
}

.products .widget-1.bild_links .widget-1-inner {
	min-height: auto;
	width: 70%;
}

.widget-1.bild_links:after {
	display: block;
	content: '';
	clear: both;
	height: 1px;
}

.widget-1.bild_none .widget-title {

}

.tabs-content .widget-8 {
	/*margin-bottom: 100px;*/
}


/**
 * 9.6 - Headline
 */

.widget-6 {
	margin: 0;
}


/**
 * 9.13 - Headline
 */

.widget-13 {
	margin: 0;
}


/**
 * 9.2 - Product search
 */

.widget-2 .grey-bg {
	padding: 15px 20px;
}

.widget-2 .grey-bg p {
	margin: 0;
}

.widget-2 .violet-bg {
	position: relative;
	padding: 10px 20px;
}

.widget-2 input[type="submit"] {
	float: right;
	margin-top: 10px;
	min-width: 45%;
}

.widget-2 .violet-bg .link {
	color: #fff;
}

.site-content .dgwt-wcas-search-wrapp {
	min-width: inherit;
	max-width: none;
	margin-bottom: 10px;
}

.site-content .dgwt-wcas-sf-wrapp {
	background: transparent;
	border-radius: 0 !important;
	box-shadow: none;
}

.site-content .dgwt-wcas-sf-wrapp .dgwt-wcas-search-input {
	height: 40px;
	padding: 10px 15px;
	border: 0;
	border: 0 none;
	border-radius: 0 !important;
}

.site-content .dgwt-wcas-sf-wrapp input[type="search"].dgwt-wcas-search-input:focus {
	box-shadow: none;
}

.site-content .dgwt-wcas-sf-wrapp .dgwt-wcas-search-submit {
	height: 40px;
	min-width: 50px;
	padding: 0 15px;
	text-transform: none;
	background-color: $theme;
	border-radius: 0 !important;
	text-shadow: none;
}

.site-content .dgwt-wcas-sf-wrapp .dgwt-wcas-search-submit:hover {
	background-color: $mid-gray;
	opacity: 1;
}

.site-content .dgwt-wcas-sf-wrapp .dgwt-wcas-search-submit:before {
	border-right-color: $theme;
}

.site-content .dgwt-wcas-sf-wrapp .dgwt-wcas-search-submit:hover:before {
	border-right-color: $mid-gray;
}

.site-content .dgwt-wcas-suggestion {
	padding: 9px 0;
}

.site-content .dgwt-wcas-open .dgwt-wcas-sf-wrapp .dgwt-wcas-search-input {
	border-radius: 0;
}

.site-content .dgwt-wcas-open .dgwt-wcas-sf-wrapp .dgwt-wcas-search-submit {
	border-radius: 0 !important;
}


/**
 * 9.3 - Featured link list
 */

.widget-3 .grey-bg {
	padding: 15px 20px;
}

.widget-3 ul.featured-link-list {
	list-style: none;
	margin: 0 -20px;
}

.widget-3 ul.featured-link-list li {
	position: relative;
	display: block;
	background-color: #500039;
	margin-bottom: 5px;
	padding: 5px 20px;
	overflow: hidden;
}

.widget-3 ul.featured-link-list li span {
	display: block;
	position: absolute;
	top: 0;
	left: 20px;
	padding: 8px 0;
	color: #fff;
}

.widget-3 ul.featured-link-list li .button {
	float: right;
	min-width: 45%;
}


/**
 * 9.4 - Intro text area
 */

.widget-4 .intro_paragraph {
	margin-bottom: 50px;
	padding-right: 30px;
	color: $mid-gray;
	font-size: 17px;
}


/**
 * 9.5 - Big image tiles
 */

.widget-5 {
	position: relative;
	display: block;
	padding-bottom: 55%;
	margin-bottom: 0;
	background-repeat: no-repeat;
	background-size: cover;
}

.widget-5 .title {
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 30px;
	color: #fff;
	font-size: 48px;
	line-height: 1;

	-webkit-transition: background-color 0.2s;
	transition: background-color 0.2s;
}

.widget-5 .title:hover {
	background-color: rgba(0, 0, 0, 0.4);
}


/**
 * 9.6 - Headline
 */

.widget-6 .headline {
	margin-bottom: 10px;
	color: $mid-gray;
}

.widget-6 .headline.underline {
	border-bottom: 1px solid $light-gray;
	padding-bottom: 15px;
}

.site-content.lightgrey .widget-6 .headline {
	border-color: #ccc;
}


/**
 * 9.7 - Google Maps
 */

.widget-7 {
	margin-bottom: 20px;
}

/* Find more styles in css/style-addons.css */


/**
 * 9.9 - Divider
 */

.widget-9 {
	border-bottom: 1px solid $light-gray;
}

.site-content.lightgrey .widget-9 {
	border-color: #ccc;
}


/**
 * 9.11 - Table
 */

.widget-11 {
	margin-bottom: 30px;
}

.main-content table {
	width: 100% !important;
	border-collapse: separate;
	border-spacing: 0 3px;
}

.main-content table.head {
	border-top: 1px solid $light-gray;
}

.main-content table tr {
	height: auto !important;
}

.main-content table thead tr th,
.main-content table thead tr td,
.main-content table tbody tr td {
	border-spacing: 0;
	margin: 0;
	vertical-align: top;
	line-height: 24px;
}

.main-content table thead tr th,
.main-content table thead tr td,
.main-content table tbody tr.header td {
	font-family: "BrixSansMedium", "Helvetica Neue", Arial, serif;
	padding: 6px 0 6px 5px;
}

.main-content table tbody tr td {
	background-color: #f3f3f4;
	padding: 3px 0 3px 5px;
}

.main-content table tbody tr td:last-child,
.main-content table thead tr th:last-child,
.main-content table thead tr td:last-child,
.main-content table tbody tr.header td:last-child {
	padding-right: 5px;
}

.main-content table tbody tr.header td {
	background-color: transparent;
	padding-top: 20px;
}

.main-content table thead tr th a,
.main-content table thead tr td a,
.main-content table tbody tr td a {
	position: relative;
	display: inline-block;
	padding-left: 10px;
	text-decoration: underline;
}

.main-content table thead tr th a:after,
.main-content table thead tr td a:after,
.main-content table tbody tr td a:after {
	position: absolute;
	display: block;
	content: '\f104';
	top: 0;
	left: 0;
	font-family: "Genericons";
	font-size: 8px;
	line-height: 25px;
}

.main-content table thead tr th .featured,
.main-content table thead tr td .featured,
.main-content table tbody tr td .featured {
	color: $theme;
	font-size: 18px;
	margin-left: -10px;
}

.tabs-content .main-content table.head {
	border-color: #fff;
}

.tabs-content .main-content table tbody tr td {
	background-color: #fff;
}


/**
 * 9.12 - Block List
 */

.widget-12 {
	margin-bottom: 30px;
}

ul.block-list {
	list-style: none;
	margin-left: 0;
}

ul.block-list li {
	margin: 0 0 5px 0;
	padding: 6px 10% 8px 10%;
	background-color: $light-gray;
}

ul.block-list li.big-sp {
	padding: 15px 20% 20px 20%;
}

.main-content.lightgrey ul.block-list li {
	background-color: #fff;
}

ul.block-list li *:last-child {
	margin-bottom: 0;
}

ul.block-list p {
	margin: 0;
}


/**
 * 9.15 - Contact center
 */

.widget-15 {
	margin-bottom: 30px;
}

.contactcenter-list {

}

.contactcenter-item {
	border-bottom: 1px solid #999;
	margin-bottom: 20px;
	padding-bottom: 40px;
	width: 100%;
}

.contactcenter-item .title {
	display: block;
	margin-bottom: 20px;
	color: $theme;
	font-size: 20px;
	line-height: 1.2;
}

.contactcenter-item .title-download {
	font-family: "BrixSansMedium", "Helvetica Neue", Arial, serif;
}

.contactcenter-item .download-list {
	list-style: none;
	margin: 0 0 20px 0;
}

.contactcenter-item .download-item a {
	position: relative;
	padding-left: 10px;
	text-decoration: underline;
}

.contactcenter-item .download-item a:after {
	position: absolute;
	content: '\f104';
	top: 5px;
	left: 0;
	font-family: "Genericons";
	font-size: 7px;
}


/**
 * 9.16 - Career center
 */

.widget-16 {
	margin-bottom: 30px;
}

.career-list {

}

.career-item {
	display: block;
	border-bottom: 1px solid #999;
	margin-bottom: 20px;
	padding-bottom: 20px;
	width: 100%;
}

.career-item .title {
	display: block;
	margin-bottom: 20px;
	color: $theme;
	font-size: 20px;
	line-height: 1.2;
}

.career-item > p {
	color: $mid-gray;
}


/**
 * 10.0 - Widgets (Sidebar)
 */

.sidebar-widget {
	background-color: #f3f3f4;
	margin-bottom: 30px;
	padding: 20px;

}

.sidebar-widget .sidebar-widget-title {
	display: block;
	margin: 0 0 15px;
	padding-bottom: 10px;
	border-bottom: 3px solid $theme;
	color: $mid-gray;
	font-size: 18px;
	line-height: 1.2;
}

.sidebar-widget .sidebar-widget-title:empty {
	margin-bottom: 0;
}


/**
 * 10.1 - Latest posts
 */

.sidebar-widget-1 {

}

.sidebar-widget-1 .news-block {
	margin-top: 20px;
}

.sidebar-widget-1 .news-block .title-link {
	position: relative;
	display: block;
	padding-left: 20px;
}

.sidebar-widget-1 .news-block .title-link:before {
	position: absolute;
	display: inline-block;
	top: 3px;
	left: 0;
	content: '\f104';
	font-family: "Genericons";
	font-size: 9px;
	text-decoration: none;
}

.sidebar-widget-1 .news-block .title {
	display: block;
	font-family: "BrixSansMedium", "Helvetica Neue", Arial, serif;
}

.sidebar-post-list {
	list-style: none;
	margin: 0;
	border-bottom: 1px solid #ccc;
	padding-bottom: 20px;
}

.sidebar-widget-1 .news-block:last-child .sidebar-post-list {
	border-bottom: 0 none;
	padding: 0;
}

.sidebar-post-list li {
	margin-top: 20px;
}

.sidebar-post-list li:first-child {
	margin-top: 10px;
}

.sidebar-post-list li .date {
	display: block;
	margin-bottom: 5px;
}

.sidebar-post-list li .link {
	position: relative;
	display: block;
	padding-left: 10px;
	text-decoration: underline;
	line-height: 1.3;
}

.sidebar-post-list li .link:before {
	position: absolute;
	display: inline-block;
	top: 3px;
	left: 0;
	content: '\f104';
	font-family: "Genericons";
	font-size: 9px;
	text-decoration: none;
}


/**
 * 10.2 - Text Area
 */

.sidebar-widget-2 {

}

.textarea a {
	position: relative;
	text-decoration: underline;
	padding-left: 10px;
	line-height: 1.6;
}

.textarea a:before {
	position: absolute;
	display: inline-block;
	top: 5px;
	left: 0;
	content: '\f104';
	font-family: "Genericons";
	font-size: 9px;
	text-decoration: none;
}


/**
 * 10.3 - Menu
 */

.sidebar-widget-3 {
	background-color: #500039;
}

body.top-bild-intro .sidebar-menu {
	margin-top: -50px;
}

body.top-bild-sub .sidebar-menu {
	margin-top: -70px;
}

.sidebar-widget-3 ul.menu,
.sidebar-widget-3 ul.sub-menu {
	list-style: none;
	margin-left: 0;
}

.sidebar-widget-3 ul.menu li.menu-item a {
	position: relative;
	display: block;
	padding-left: 10px;
	color: #fff;
	font-size: 16px;
	line-height: 1.6;
}

.sidebar-widget-3 ul.menu > li.menu-item:first-child > a {
	display: block;
	margin: 0 0 15px;
	padding-bottom: 10px;
	border-bottom: 3px solid $theme;
	color: #fff;
	font-size: 18px;
	line-height: 1.2;
}

.sidebar-widget-3 ul.menu li.menu-item > a:after {
	position: absolute;
	content: '\f104';
	top: 5px;
	left: 0;
	font-family: "Genericons";
	font-size: 7px;
}

.sidebar-widget-3 ul.sub-menu ul.sub-menu {
	display: none;
	margin-left: 25px;
}

.sidebar-widget-3 ul.sub-menu li.current-menu-item > ul.sub-menu,
.sidebar-widget-3 ul.sub-menu li.current-menu-parent > ul.sub-menu,
.sidebar-widget-3 ul.sub-menu li.current-menu-ancestor > ul.sub-menu {
	display: block;
}

.sidebar-widget-3 ul.sub-menu li.current-product-item > ul.sub-menu,
.sidebar-widget-3 ul.sub-menu li.current-product-parent > ul.sub-menu,
.sidebar-widget-3 ul.sub-menu li.current-product-ancestor > ul.sub-menu {
	display: block;
}

.sidebar-widget-3 ul.sub-menu ul.sub-menu li.menu-item > a:after,
.sidebar-widget-3 ul.menu > li.menu-item:first-child > a:after {
	content: none;
}

.sidebar-widget-3 ul.sub-menu ul.sub-menu li.menu-item > a,
.sidebar-widget-3 ul.menu > li.menu-item:first-child > a {
	padding-left: 0;
}

.sidebar-widget-3 ul.menu li.menu-item > a:hover,
.sidebar-widget-3 ul.menu li.current-menu-item > a,
.sidebar-widget-3 ul.menu li.current-menu-parent > a,
.sidebar-widget-3 ul.menu li.current-menu-ancestor > a {
	color: $theme;
}

.sidebar-widget-3 ul.menu li.menu-item > a:hover,
.sidebar-widget-3 ul.menu li.current-product-item > a,
.sidebar-widget-3 ul.menu li.current-product-parent > a,
.sidebar-widget-3 ul.menu li.current-product-ancestor > a {
	color: $theme;
}


/**
 * 10.0 - Content
 */

#site-wrapper {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	text-align: center;

	-webkit-transition: -webkit-transform 0.5s;
	-ms-transition: transform 0.5s;
	transition: transform 0.5s;

	-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

body.openmenu #site-wrapper {

	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;

	-webkit-transform: translate3d(-240px, 0, 0);
	-ms-transform: translate3d(-240px, 0, 0);
	transform: translate3d(-240px, 0, 0);
}

.site-content {
	position: relative;
	background-color: transparent;
	width: 100%;
	text-align: center;

	z-index: 12;
}

.site-content.white {
	background-color: #fff;
}

.site-content.white > .content-aligning {
	background-color: #fff;
}

.site-content.lightgrey {
	background-color: $light-gray;
}

.site-content.lightgrey > .content-aligning {
	background-color: $light-gray;
}

.slider-wrapper.lightgrey {
	background-color: $light-gray;
}

.site-content.intro.item-1 {
	margin-top: -75px;
	background-color: transparent !important;
}

.site-content.intro.item-1 .main-content {
	margin-top: 50px;
}

.site-content.sub.item-1 {
	margin-top: 20px;
}

.full-content {
	position: relative;
	background-color: transparent;
	width: 100%;
	text-align: left;
}

.content-aligning {
	position: relative;
	width: 100%;
	max-width: 1320px;
	padding: 0 40px;
	margin: 0 auto;
	text-align: left;
	box-sizing: border-box;
}

.content-aligning:before,
.content-aligning:after {
	content: "";
	display: table;
}

.content-aligning:after {
	clear: both;
	zoom: 1; /* ie 6/7 */
}

.errorlist {
	list-style: none;
	margin: 0;

	li {
		padding: 1rem 1.75rem 1rem 1rem;
		background: $theme;
		margin-bottom: 1rem;
		color: #fff;
	}
}

.main-content {
	width: 69%;
	margin: 0 0 50px 0;
	padding-top: 30px;
	overflow: hidden;

	img {
		max-width: 100%;
		height: auto;
	}
}

.main-content.sidebar-left {
	float: right;
}

.main-content.sidebar-right {
	float: left;
}

.main-content.sidebar-none {
	float: none;
	width: 100%;
}

.sidebar {
	width: 25%;
	margin-top: 30px;
}

.tabs-content .sidebar {
	margin-top: 0;
}

.sidebar.sidebar-left {
	float: left;
	margin-right: 6%;
}

.sidebar.sidebar-right {
	float: right;
	margin-left: 6%;
}

.teaser-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	transition-property: transform;
	box-sizing: content-box;
}

.teaser-image {
	-webkit-flex-shrink: 0;
	-ms-flex: 0 0 auto;
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

#teaser-top-teaser.swiper-container-autoheight,
#teaser-top-teaser.swiper-container-autoheight .swiper-slide {
	height: 100%;
}

.slider-content.swiper-container-horizontal > .swiper-pagination-bullets {
	position: relative;
	bottom: auto;
	margin-top: 50px;
}

.slider-content .swiper-pagination .swiper-pagination-bullet {
	position: relative;
	width: 12px;
	height: 12px;
	border-radius: 100%;
	background-color: #fff;
}

.slider-content .swiper-pagination .swiper-pagination-bullet:after {
	position: absolute;
	display: block;
	content: '';
	width: 6px;
	height: 6px;
	top: 3px;
	left: 3px;
	border-radius: 100%;
	background-color: #999;
}

.slider-content .swiper-pagination .swiper-pagination-bullet-active:after {
	background-color: $theme;
}

.accordion-tab {
	position: relative;
	display: block;
	margin-bottom: 30px;
	padding: 10px 0 12px 0;
	border-top: 1px solid $light-gray;
	font-size: 20px;
	cursor: pointer;

	transition: 0.3s ease-in-out;
}

.main-content.lightgrey .accordion-tab {
	border-color: #ccc;
}

.tabs-content .accordion-tab {
	border-color: #aaa;
}

.accordion-tab.show {
	margin-bottom: 0;
}

.accordion-tab .accordion-icon {
	position: absolute;
	display: block;
	top: 6px;
	right: 0;
	width: 30px;
	height: 30px;
	border: 1px solid $theme;
}

.accordion-tab .accordion-icon:after {
	display: block;
	content: '\f431';
	color: $theme;
	font-family: "Genericons";
	font-size: 16px;
	line-height: 30px;
	text-align: center;
}

.accordion-tab.show .accordion-icon:after {
	content: '\f432';
}

.accordion-tab:hover .accordion-icon {
	border-color: #999;
}

.accordion-tab:hover .accordion-icon:after {
	color: #999;
}

.accordion-body {
	position: relative;
	height: auto;
	max-height: 0;
	transition: 0.6s ease-in-out;
	opacity: 0;
	overflow: hidden;
}

.accordion-body.show {
	max-height: 6000px;
	opacity: 1;
}


/**
 * 10.0 - References
 */

.filter {
	background-color: #f3f3f4;
	margin-bottom: 30px;
	padding: 20px;
}

.filter:after {
	content: "";
	display: block;
	clear: both;
}

.filter span.filter-head {
	display: block;
	margin-bottom: 20px;
	font-size: 16px;
	line-height: 1;
}

.filter ul.dropdown-list {
	width: 49%;
}

.filter ul.dropdown-list.category,
.filter ul.dropdown-list.domain_contact,
.filter ul.dropdown-list.domain_career {
	margin-right: 2%;
}

.ref-wrapper {
	overflow: hidden;
	min-height: 500px;
}

.ref-item {
	position: relative;
	display: block;
	background-color: $light-gray;
	float: left;
	width: 25%;
}

.ref-item .img {
	position: relative;
	width: 100%;
}

.ref-item .img:before {
	content: "";
	display: block;
	padding-top: 65%;
}

.ref-item .img .img-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #ccc;
	background-size: cover;
	background-repeat: no-repeat;
}

.ref-item .img .img-container:after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: inline-block;

	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjY1Ii8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=0); /* IE6-8 */

}

.ref-item:hover .img .img-container:after {
	opacity: 0.7;

}

.ref-item .img span.img-title {
	position: absolute;
	display: block;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 15px 20px;
	color: #fff;
	font-size: 24px;
	line-height: 1;
}

.ref-item p.ref-info {
	position: relative;
	display: block;
	height: 100px;
	margin: 0;
	padding: 20px 20% 0 20px;
	color: #1a1a1a;
	font-family: "BrixSansMedium", "Helvetica Neue", Arial, serif;
	font-size: 16px;
	line-height: 20px;
	border-left: 1px solid #ddd;

	-webkit-transition: color 0.3s;
	transition: color 0.3s;
}

.ref-item p.ref-info:before {
	position: absolute;
	display: block;
	content: '\f429';
	top: 50%;
	right: 10px;
	border: 1px solid $theme;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	color: $theme;
	font-family: "Genericons";
	font-size: 20px;
	line-height: 18px;
}

a.ref-item:hover p.ref-info {
	color: $theme;
}

a.ref-backlink {
	position: relative;
	display: block;
	margin-bottom: 20px;
	padding-left: 20px;
	text-decoration: underline;
}

a.ref-backlink:before {
	position: absolute;
	display: block;
	content: '\f430';
	top: 0;
	left: 0;
	font-family: "Genericons";
	font-size: 18px;
	line-height: 1.3;
	text-decoration: none;
}

ul.ref-list {
	list-style-position: inside;
	list-style-type: disc;
	color: $mid-gray;
}

ul.ref-list li span {
	color: $dark-gray;
}

body.single-references .content-slider {
	margin-top: 50px;
	margin-bottom: 100px;
	overflow: hidden;
}

body.single-references .content-slider .swiper-slide {
	background-color: #f3f3f4;
}

.ref-recommend {
	background-color: #f3f3f4;
	margin-top: 50px;
	margin-bottom: 30px;
	padding: 20px;
}

.ref-recommend:after {
	display: block;
	content: "";
	clear: both;
}

.ref-recommend h5 {
	display: block;
	margin: 0 0 15px;
	padding-bottom: 10px;
	border-bottom: 3px solid $theme;
	color: $mid-gray;
	font-size: 18px;
	line-height: 1.2;
}

.ref-recommend-content {

}

.ref-recommend-item {
	display: block;
	float: left;
	width: 32%;
	margin-left: 2%;
}

.ref-recommend-item:first-child {
	margin-left: 0;
}

.ref-recommend-item span.title {
	position: relative;
	display: block;
	margin: 15px 0;
	padding-left: 15px;
	line-height: 1.3;
	text-decoration: underline;
}

.ref-recommend-item span.title:before {
	position: absolute;
	display: block;
	top: 3px;
	left: 0;
	content: '\f104';
	font-family: "Genericons";
	font-size: 9px;
}


/**
 * 10.0 - Products
 */

.wc-loop-block {
	margin-bottom: 30px;
}

.products-archive-header {
	position: relative;
}

.woocommerce-main-image {
	display: block;
	margin-bottom: 30px;
}

.product-item-header {
	position: relative;
	margin-bottom: 30px;
}

.product-item-image {
	width: 50%;
	float: left;
	margin: 3px 5% 30px 0;
}

#site-main {
	position: relative;

	.toast-notification {
		position: absolute;
		top: 1rem;
		right: 1rem;
		z-index: 99999;

		.close {
			position: absolute;
			top: -5px;
			right: 5px;
			font-size: 20px;
			color: #fff;
		}

		&:hover {
			cursor: pointer;
		}
	}
}

.woocommerce-main-image {
	margin-bottom: 0;
}

.product-item-summary {
	width: 45%;
	float: left;
	margin-bottom: 30px;
}

.product-item-richtext {
	margin-bottom: 30px;
}

.product-item-summary:after {
	display: block;
	content: '';
	clear: both;
}

.product-item-backlink {
	padding-left: 25px;
	padding-bottom: 20px;
	font-size: 15px;
}

.product-item-backlink:before {
	position: absolute;
	top: 1px;
	left: 0;
	content: '\f430';
	font-family: "Genericons";
	font-size: 20px;
	line-height: 1;
}

.product-tags {
	margin-top: 20px;
	font-family: $font-family-light;
	text-align: right;
	margin-bottom: -20px;
}

.site-content.intro.item-1 .product-tags {
	margin-bottom: -70px;
}

.products-archive-header .product-tags {
	position: relative;
	right: auto;
	margin-bottom: 20px;
}

body.top-bild-intro .products-archive-header .product-tags {
	margin-top: -50px;
}

body.top-bild-sub .products-archive-header .product-tags {
	margin-top: -30px;
}

body.single .product-tags {
	margin-top: 0;
	position: absolute;
	top: -20px;
	right: 0;
}

.product-tags p {
	margin: 0;
}

p.product-tag {
	margin: 0;
	color: $theme;
}

.product-item-header h1 {
	margin-top: 20px;
	font-size: 42px;
	line-height: 1.2;
}

.wc-tabs-wrapper {
	margin-bottom: 60px;
}


/**
 * 10.1 - Top bar
 */

#site-topbar {
	position: absolute;
	z-index: 223;
	background-color: transparent;
	background-repeat: no-repeat;
	right: 0;
	//width: calc(50% + 100px);
	width: 100%;
	min-width: 590px;
	height: 30px;
	text-align: left;
	padding-top: 8px;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		z-index: 9999999;
		//background-color: darkblue;
		background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAuwAAAAeCAYAAAB35UvHAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+1JREFUeNrs3NtqVFccwOH/TCadxCSiBRWVXggWWwRLfAMfwUfII/SNcu2VjxBBqWjR2JuiWDwRbFOxOtqYw8zEtQMKxUP2JPs06feDDXMx7Fmz2LPmY7HZre1UVNz2214Mf7sZ0d+KJrS+thZPri/FYHMjmtLR7mScnukWM9/3fol49keMU282+3FrtRf94e6XZ7sVcepQxDftesb693oa71ZIqqh2qxOnDp+P7sSMyShj/d1YjdV/H5qIiprrHo/jM2dNxAgda8/FXGtqd/8M1mLw5n72Ypy/7qt0XKqcOLAO67AuCdZhXbAO6/mwPv1oZblS5sA6rMO6JFiHdcE6rOdqIcP6zvoH67AO67AuwTqswzqsw3rjsH714xoI67AO67AuwTqsC9ZhvVFYX/zPOgjrsA7rsC7BOqwL1mG9mVgvHeywDuuwLgnWYV2wDut7x3pWq6zHOmZYH9xtDtZfJ6z/em0phhvNwfp3k5Pxw3QxWB/eTlh/Ol5Y/7Pfjyv/JKznuAQ76Tif/rOna8L6g4T1l7AuVVYnYf1CwvoUrJfS84T1R7BeWScT1s/A+kh9PzEX3+bE+lZv/LHe7sTC7Mrnsf7BQaVgvX+nOVjvJazfWFqKrYZh/dxkN7b7xWB9+/H4YX3xdS/W82C9FfHToezCinhXw+/x/rs0XliXKsX6xYT1iJlYH5iPMrD++1tYrxLrJ6fOupZH6FzC+tHhVOwmhAzrm72DsbN++K8vY30H9LBeD9bnu8XtrP8fsD47Uc9YYV2qB+uzHTvrsH4wsP7jrJ31UbF+IufO+gHB+s9HXnwd61mF3hID67AO65JgHdYF67Ceq8WE9YU8byxshx3WYR3WJcE6rAvWYb1YrBcGdliHdViXBOuwLliH9eKxXgjYYR3WYV0SrMO6YB3Wy8H6vsEO67AO65JgHdYF67BeHtb3BXZYh3VYlwTrsC5Yh/Vysb5nsMM6rMO6JFiHdcE6rJeP9ayRH+sI67AO65JgHdYF67Ceq+WE9fn9nmSkHXZYh3VYlwTrsC5Yh/V8WE/HpSJOlBvssA7rsC4J1mFdsA7r+bF+5MXKq8rADuuwDuuSYB3WBeuwXj3Wc4Ed1mEd1iXBOqwL1mG9HqzvCnZYh3VYlwTrsC5Yh/X6sP5VsMM6rMO6JFiHdcE6rNeL9azPPtYR1mEd1iXBOqwL1mE9V4/TMV8W1rPasA7rsC4J1mFdsA7reypD+uUysf4J2GEd1mFdEqzDumAd1nNjPbsNZrnsD2rDOqzDuiRYh3XBOqw3E+sfwQ7rsA7rkmAd1gXrsN48rO+AHdZhHdYlwTqsC9ZhvZlYz3ovwABCIhqUQSNQmwAAAABJRU5ErkJggg==') no-repeat;
		width: calc(50% + 100px);
		background-position: 100px center;
		height: 30px;
    	background-size: 100% 100%;
	}
}

#site-topbar-inner {
	position: relative;
	width: 100%;
    z-index: 99999999999;
}

.grid-item {
	padding: 1rem;

	.widget-title {
		margin-top: 0;
	}
}

/**
 * 10.2 - Header
 */

#site-header {
	position: relative;
	z-index: 222;
	//padding-bottom: 20px;
	//margin-bottom: 20px;
	border-bottom: 1px solid $light-gray;
	background-color: #fff;
}

#site-header a.logo {
	position: absolute;
	display: block;
	width: 300px;
	height: 117%;
	top: -17%;
	left: -40px;
	overflow: hidden;
}

#site-header a.logo img {
	display: block;
	width: 100%;
	height: auto;
}


/**
 * 10.3 - Header Large DropDown
 */

.btn {
	cursor: pointer;

	&:hover {
		cursor: pointer;
	}
}

.input-group {
	margin-bottom: .75rem;

	&.button-group {
		margin-bottom: 0;
		margin-top: 1.75rem;
	}
}

#header-large-dropdown {
	position: absolute;
	z-index: 220;
	width: 100%;
	margin-top: -70px;
}

#header-large-dropdown .btn-close {
	float: right;
	position: absolute;
	right: 10px;
	top: 15px;
	cursor: pointer;
}

#header-large-dropdown .btn-close > span:before {
	color: #333;
	display: table-cell;
	content: '\f406';
	font-family: "Genericons";
	font-size: 14px;
	font-weight: 300;
	height: 33px;
	line-height: 1.1;
	text-align: center;
	vertical-align: middle;
	width: 33px;
}

#header-large-dropdown-inner.show {
}

#header-large-dropdown-inner {
	position: absolute;
	width: 100%;
	min-height: 150px;
	top: 0;
	background-color: #fff;
	color: #000;
	text-align: center;
	-webkit-transition: -webkit-transform .3s ease;
	-ms-transition: -ms-transform .3s ease;
	transition: transform 0.3s ease;
	-webkit-transform: translate3d(0, -150px, 0);
	-ms-transform: translate3d(0, -150px, 0);
	transform: translate(0, -150px);

	background-color: #f7f7f7;
}

#header-large-dropdown-inner.show {
	-webkit-transform: translate3d(0, 0, 0);
	/* iphone, android */
	-ms-transform: translate3d(0, 0, 0);
	/* windows phone */
	transform: translate(0, 0);
	/* desktop */
}

#header-large-dropdown .main-nav-inner {
	display: none;
	/*display: block !important;*/
}

#header-large-dropdown .main-nav-dropdown {
	margin-top: 40px;
	margin-bottom: 30px;
}

#header-large-dropdown .main-nav-dropdown.grid > .grid__col {
	margin-right: 0;
}

#header-large-dropdown .main-nav-dropdown li:last-child ul {
	padding-right: 0;
}

#header-large-dropdown .main-nav-dropdown .modul {
	margin-bottom: 25px;
}

#header-large-dropdown .main-nav-dropdown .modul .title {
	display: block;
	color: $dark-gray;
	font-size: 17px;
	font-family: "BrixSansMedium", "Helvetica Neue", Arial, serif;
	line-height: 1.2;

	margin: 0 0 5px;
	padding-bottom: 7px;
	border-bottom: 2px solid #e30613;
}

#header-large-dropdown .main-nav-dropdown .modul_1 .title a {
	color: $dark-gray;
}

#header-large-dropdown .main-nav-dropdown .modul_1 .title a:hover {
	color: #e30613;
}

#header-large-dropdown .main-nav-dropdown .modul_1 ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

#header-large-dropdown .main-nav-dropdown .modul_1 ul li {
	font-size: 13px;
	line-height: 22px;
	border-bottom: 1px solid #ddd;
}

#header-large-dropdown .main-nav-dropdown .modul_1 ul li a {
	position: relative;
	display: block;
	padding: 5px 0;
	color: $dark-gray;
	font-size: 16px;
	line-height: 1;
}

#header-large-dropdown .main-nav-dropdown .modul_1 ul li a:hover {
	color: #e30613;
}

#header-large-dropdown .main-nav-dropdown .modul_2 a.img-block {
	display: block;
}

#header-large-dropdown .main-nav-dropdown .modul_2 a.img-block .title {
	margin-bottom: 10px;
	transition: color 0.3s;
}

#header-large-dropdown .main-nav-dropdown .modul_2 a.img-block:hover .title {
	color: #e30613;
}


/**
 * 10.3 - Top images
 */

#teaser-top {
	width: 100%;
	margin-top: -70px;
}

#teaser-top-teaser {
	position: absolute;
	z-index: 2;
	top: 115px;
	width: 100%;
	background: transparent;
	pointer-events: none;
}

#teaser-top.home:after {
	content: '';
	position: absolute;
	z-index: 2;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: inline-block;

	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjY1Ii8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=0); /* IE6-8 */

}

#teaser-top.home,
#teaser-top-teaser.home {
	height: 480px;
}

#teaser-top.intro,
#teaser-top-teaser.intro {
	height: 480px;
}

#teaser-top.sub,
#teaser-top-teaser.sub {
	height: 400px;
	text-align: center;
}

#teaser-top.sub .swiper-wrapper,
#teaser-top-teaser.sub .swiper-wrapper {
	width: 100%;
	max-width: 1020px;
	margin: 0 auto;
	text-align: left;
}

#teaser-top .swiper-slide {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

#teaser-top .swiper-slide.block-img {
	position: relative;
	background: none;
}

#teaser-top .swiper-slide.block-img > img {
	position: absolute;
	display: block;
	top: 50%;
	left: 50%;
	-webkit-transform: translate3d(-50%, -50%, 0);
	-ms-transform: translate3d(-50%, -50%, 0);
	transform: translate3d(-50%, -50%, 0);
}

.slide-content {
	position: relative;
	height: 100%;
}

.slide-teaser {
	position: absolute;
	bottom: 60px;
	padding: 15px;
	width: 45%;
	color: #fff;
	box-sizing: border-box;
}

.slide-teaser.transparent {
	background-color: transparent;
}

.slide-teaser.black {
	background-color: rgba(0, 0, 0, 0.5);
}

.slide-teaser h4 {
	margin-bottom: 30px;
	font-weight: normal;
	font-family: $font-family-light;
	font-size: 36px;
	line-height: 36px;
}

.slide-teaser a {
	display: inline-block;
	background-color: #e30613;
	padding: 8px 15px;
	color: #fff;
	line-height: 1;
	pointer-events: auto;
}

.slide-teaser a:hover {
	background-color: $mid-gray;
}

.swiper-button-prev,
.swiper-button-next {
	width: 50px;
	height: 50px;
	background-color: rgba(255, 255, 255, 0.5);
	margin-top: -25px;
	background-position: center;
	background-size: auto 60%;
}

.swiper-button-prev {
	left: 0;
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNy43IDM0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNy43IDM0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojRkZGRkZGO3N0cm9rZS1taXRlcmxpbWl0OjEwO30NCjwvc3R5bGU+DQo8Zz4NCgk8cG9seWxpbmUgY2xhc3M9InN0MCIgcG9pbnRzPSIxNy4zLDAuNCAwLjcsMTcgMTcuMywzMy42IAkiLz4NCjwvZz4NCjwvc3ZnPg0K");
}

.swiper-button-next {
	right: 0;
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNy43IDM0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNy43IDM0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojRkZGRkZGO3N0cm9rZS1taXRlcmxpbWl0OjEwO30NCjwvc3R5bGU+DQo8Zz4NCgk8cG9seWxpbmUgY2xhc3M9InN0MCIgcG9pbnRzPSIwLjcsMC40IDE3LjMsMTcgMC43LDMzLjYgCSIvPg0KPC9nPg0KPC9zdmc+DQo=");
}

.swiper-pagination .swiper-pagination-bullet {
	background-color: #fff;
	opacity: 1;
	border-radius: 0;
	width: 10px;
	height: 10px;
}

.swiper-pagination .swiper-pagination-bullet-active {
	background-color: #e30613;
	opacity: 1;
}


/**
 * 10.4 - Product Search Page
 */

.product-search-content {
	position: relative;
	width: 80%;
}


/**
 * 10.6 - Product Taxonomy Page
 */

.product-search-tax {
	position: relative;
	width: 100%;
}


/**
 * 10.5 - News Search Page
 */

.news-search-content {
	position: relative;
	width: 100%;
}


/**
 * 10.5 - Tabs/Slider
 */

.tabs-wrapper {
	margin-top: 30px;
}

.tabs-wrapper .swiper-wrapper {
}

.slider-wrapper {
	margin-top: 0;
	padding-bottom: 20px;
}

.slider-wrapper .swiper-button-prev,
.slider-wrapper .swiper-button-next {
	background-color: transparent;
	background-size: auto 100%;
}

.slider-wrapper .swiper-button-prev {
	left: 0;
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNS45IDUwLjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1LjkgNTAuNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6I0VFMUMyNTtzdHJva2UtbWl0ZXJsaW1pdDoxMDt9DQo8L3N0eWxlPg0KPHBvbHlsaW5lIGNsYXNzPSJzdDAiIHBvaW50cz0iMjUuMiwwLjQgMC40LDI1LjIgMjUuMiw1MCAiLz4NCjwvc3ZnPg0K");
}

.slider-wrapper .swiper-button-next {
	right: 0;
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNS45IDUwLjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1LjkgNTAuNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6I0VFMUMyNTtzdHJva2UtbWl0ZXJsaW1pdDoxMDt9DQo8L3N0eWxlPg0KPHBvbHlsaW5lIGNsYXNzPSJzdDAiIHBvaW50cz0iMC40LDAuNCAyNS4yLDI1LjIgMC40LDUwICIvPg0KPC9zdmc+DQo=");
}

.tabs-wrapper:after {
	display: block;
	content: "";
	clear: both;
	height: 1px;
}

ul.tabs-button,
ul.tabs-button-group {
	position: relative;
	z-index: 2;
	display: block;
	list-style: none;

	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

ul.tabs-button ul.tabs-button-group {
	margin-left: 0;
}

li.tab-button-group {
	display: block;
	width: 100%;
}

ul.tabs-button li.tab-button,
ul.tabs-button li.tab-mobile {
	position: relative;
	display: block;
	float: left;
	background-color: #6d6e70;
	border-bottom: 1px solid #fff;
	width: 25%;
	margin: 0;
	padding: 25px 10px;
	cursor: pointer;
	text-align: center;

	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

ul.tabs-button li.tab-mobile {
	display: none;
}

ul.tabs-button li.tab-mobile:after {
	position: absolute;
	display: block;
	content: '\f419';
	right: 20px;
	top: 50%;
	height: 20px;
	margin-top: -10px;
	color: $mid-gray;
	font-family: "Genericons";
	font-size: 12px;
	line-height: 22px;
}

ul.tabs-button.count6 li.tab-button {
	width: 16.66%;
}

ul.tabs-button.count5 li.tab-button {
	width: 20%;
}

ul.tabs-button.count4 li.tab-button {
	width: 25%;
}

ul.tabs-button.count3 li.tab-button {
	width: 25%;
}

ul.tabs-button.count2 li.tab-button {
	width: 25%;
}

ul.tabs-button li.tab-button a,
ul.tabs-button li.tab-mobile a {
	width: 100%;
	color: #fff;
	font-size: 18px;
	line-height: 1.3;
	text-align: center;
}

ul.tabs-button li.tab-mobile a {
	color: $dark-gray;
}

ul.tabs-button li.tab-button:hover {
	background-color: #888;
}

ul.tabs-button li.tab-button.active {
	background-color: #b8dbe9;
}

ul.tabs-button li.tab-button:after {
	position: absolute;
	z-index: 2;
	display: block;
	content: "";
	left: 50%;
	bottom: -1px;
	margin-left: -25px;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 0 25px 0 25px;
	border-color: #b8dbe9 transparent transparent transparent;

	-webkit-transition: border-width 0.1s, bottom 0.1s;
	transition: border-width 0.1s, bottom 0.1s;
}

ul.tabs-button li.tab-button:before {
	position: absolute;
	z-index: 1;
	display: block;
	content: "";
	left: 50%;
	bottom: -2px;
	margin-left: -25px;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 0 25px 0 25px;
	border-color: #fff transparent transparent transparent;

	-webkit-transition: border-width 0.1s, bottom 0.1s;
	transition: border-width 0.1s, bottom 0.1s;
}

ul.tabs-button li.tab-button.active:after {
	bottom: -20px;
	border-width: 20px 25px 0 25px;
}

ul.tabs-button li.tab-button.active:before {
	bottom: -21px;
	border-width: 20px 25px 0 25px;
}

ul.tabs-button li.tab-button.active a {
	color: $mid-gray;
}

.tabs-content {
	position: relative;
	z-index: 1;
	background-color: #cdecf5;
	background-color: rgba(205, 236, 245, 0.7);
	padding: 80px 0 50px 0;
}

.tabs-content .main-content {
	padding: 0;
}


/**
 * 10.3 - Content images/slider
 */

.content-slider {
	position: relative;
	width: 100%;
	margin-bottom: 30px;
}

.content-slider .swiper-slide img {
	width: 100%;
}

/**
 * 10.4 - Posts and pages
 */

.site-content .posts .post {
	border-bottom: 1px solid $light-gray;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

.site-content .posts .post .date-time {
	color: #999;
}

.site-content .posts .post .date-time,
.site-content .posts .post .post-type {
	color: #000;
	font-family: "BrixSansMedium", "Helvetica Neue", Arial, serif;
	font-size: 13px;
}

.site-content .posts .post .post-excerpt {
	color: #999;
}


/**
 * 10.5 - Content Footer
 */

#site-bottom .site-bottom-line {
	border-top: 1px solid $light-gray;
	padding: 20px 0;
	text-align: left;
	overflow: hidden;
}

#site-bottom .site-bottom-line a.page-top,
#site-bottom .site-bottom-line a.print {
	position: relative;
	display: block;
	padding-left: 20px;
}

#site-bottom .site-bottom-line a.page-top:before,
#site-bottom .site-bottom-line a.print:before {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	color: #818284;
	font-family: "Genericons";
	font-size: 16px;
}

#site-bottom .site-bottom-line a.page-top {
	float: right;
}

#site-bottom .site-bottom-line a.page-top:before {
	content: '\f435';
}

#site-bottom .site-bottom-line a.print {
	float: left;
}

#site-bottom .site-bottom-line a.print:before {
	content: '\f469';
}


/**
 * 10.6 - Footer
 */

#site-footer {
	position: relative;
	z-index: 10;
	width: 100%;
	background-color: $dark-gray;
	padding: 20px 0;
	text-align: left;
}

#site-footer .footer-line {
	display: inline-block;
	margin: 30px 0;
	color: $mid-gray;
	font-size: 16px;
}

#site-footer .footer-line-imprint {
	display: inline-block;
	margin-left: 10px;
	border-left: 1px solid $mid-gray;
	padding-left: 10px;
	color: $mid-gray;
	font-size: 16px;
}

#site-footer .footer-line-imprint:hover {
	color: $theme-alt;
}


/**
 * 11.0 - Media Queries
 */


/**
 * 11.1 - >= 1100px
 */

@media screen and (min-width: 1360px) {

	#teaser-top.intro {
		height: 600px;
	}

}


/**
 * 11.1 - <= 1100px
 */

@media screen and (max-width: 1100px) {

	.slide-teaser {
		left: 10%;
		width: 40%;
	}

	.widget-5 .title {
		font-size: 32px;
	}

	.content-aligning {
		max-width: 980px;
		padding: 0 20px;
	}

	.ref-item {
		width: 33.33%;
	}

}


/**
 * 11.1 - <= 900px
 */

@media screen and (max-width: 1000px) {

	#main-navigation .menu-item {
		margin-left: 30px;
	}
	#main-navigation .menu-item a {
		font-size: 16px;
	}

}


/**
 * 11.2 - <= 900px
 */

@media screen and (max-width: 900px) {

	.main-content {
		padding-top: 0;
	}

	#site-topbar {
		background-color: transparent;
		background-position: right;
		width: 100%;
		left: auto;
		right: 100px;
		top: -15px;
	}
	#site-topbar-inner,
	.footer-links {
		display: none;
	}
	#site-header {
		height: 85px;
	}
	#site-header a.logo {
		position: relative;
		width: 200px;
	}
	#main-navigation,
	#header-large-dropdown {
		display: none;
	}
	#toggle-mobile-menu {
		display: block;
	}

	.footer-line {
		margin: 0 20px;
	}

	#teaser-top-teaser {
		top: 85px;
	}
	#teaser-top.home,
	#teaser-top-teaser.home {
		height: 600px;
	}
	#teaser-top.intro {
		height: 380px;
	}
	#teaser-top.sub {
		height: 300px;
	}
	.slide-teaser {
		left: 10%;
	}

	.main-content,
	.main-content.sidebar-none,
	.main-content.sidebar-left,
	.main-content.sidebar-right,
	body.page .main-content,
	body.page .main-content.sidebar-none,
	body.page .main-content.sidebar-left,
	body.page .main-content.sidebar-right {
		float: none;
		width: 100%;

	}

	.sidebar,
	.sidebar.sidebar-left,
	.sidebar.sidebar-right,
	body.page .sidebar,
	body.page .sidebar.sidebar-left,
	body.page .sidebar.sidebar-right {
		float: none;
		width: 100%;
		margin: 0;
	}

	.site-content.intro.item-1 {
		margin-top: 0;
	}
	.sidebar-menu {
		display: none;
		margin-top: 0;
	}

	.ref-item p.ref-info {
		font-family: $font-family-light;
		font-size: 15px;
		line-height: 18px;
	}

	ul.tabs-button li.tab-button, ul.tabs-button li.tab-mobile {
		padding: 20px 5px;
	}

	ul.tabs-button li.tab-button a,
	ul.tabs-button li.tab-mobile a {
		font-size: 15px;
	}

}


/**
 * 11.3 - <= 700px
 */

@media screen and (max-width: 700px) {

	#site-footer .footer-line {
		margin: 20px 0;
	}

	#teaser-top.home,
	#teaser-top-teaser.home {
		height: 500px;
	}
	#teaser-top.intro {
		height: 300px;
	}
	#teaser-top.sub {
		height: 250px;
	}
	.slide-teaser {
		left: 10%;
		width: 60%;
	}

	.widget-4 {
		margin-left: 0;
	}

	.ref-item {
		width: 50%;
	}


	/* Here we start with some basic reset styles */
	ul.tabs-button li.tab-button,
	ul.tabs-button.count1 li.tab-button,
	ul.tabs-button.count2 li.tab-button,
	ul.tabs-button.count3 li.tab-button,
	ul.tabs-button.count4 li.tab-button,
	ul.tabs-button.count5 li.tab-button,
	ul.tabs-button li.tab-mobile {
		display: block;
		float: none;
		border: none;
		background-color: $light-gray;
		margin: 0;
		padding: 25px 40px 25px 25px;
		width: 100%;
		text-align: left;
	}
	ul.tabs-button li.tab-button.active:after,
	ul.tabs-button li.tab-button.active:before {
		display: none;
	}

	/* Here we start with the tab button */
	.tabs-wrapper {

	}
	.tabs-wrapper ul.tabs-button {
		display: block;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		width: auto;
	}
	.tabs-wrapper ul.tabs-button li.tab-mobile {
		background-color: #cdecf5;
	}
	.tabs-wrapper ul.tabs-button li.tab-button-group {
		position: absolute;
		z-index: 2;
		left: 0;
		margin-top: 1px;
		overflow: hidden;
		min-height: 600px;

		pointer-events: none;
	}

	/* Here we start with the tab-button (mobile dropdown) */
	.tabs-wrapper ul.tabs-button li.tab-button-group ul.tabs-button-group {
		position: absolute;
		left: 0;
		margin: 0 20px;
		box-shadow: 1px 1px 3px rgba(0, 0, 0, .15);
		opacity: 0;

		-webkit-transition: -webkit-transform 0.3s ease-in-out;
		transition: 0.3s ease-in-out;

		-webkit-transform: translate3d(0, -101%, 0);
		-ms-transform: translate3d(0, -101%, 0);
		transform: translate3d(0, -101%, 0);

		pointer-events: auto;
	}
	.tabs-wrapper ul.tabs-button.show li.tab-button-group ul.tabs-button-group {
		opacity: 1;

		-webkit-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);

	}
	.tabs-wrapper ul.tabs-button li.tab-button-group ul.tabs-button-group li.tab-button {

	}
	.tabs-wrapper ul.tabs-button li.tab-button-group ul.tabs-button-group li.tab-button:hover {
		background-color: #d6d6d6;
	}
	.tabs-wrapper ul.tabs-button li.tab-button-group ul.tabs-button-group li.tab-button.active {
		background-color: $mid-gray;
	}
	.tabs-wrapper ul.tabs-button li.tab-button-group ul.tabs-button-group li.tab-button a {
		color: $mid-gray;
	}
	.tabs-wrapper ul.tabs-button li.tab-button-group ul.tabs-button-group li.tab-button.active a {
		color: #fff;
	}

}


/**
 * 11.4 - <= 480px
 */

@media screen and (max-width: 480px) {

	body, button, input, select, textarea {
		font-size: 16px;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 24px;
		line-height: 28px;
	}
	.site-content .small-headline {
		font-size: 18px;
	}

	.main-content {
		overflow: visible;
	}

	#site-header a.logo {

	}

	#site-footer {
		margin-top: 0;
	}

	#site-footer .footer-line {
		margin: 10px 0;
	}

	.site-content {
		overflow: hidden;
	}

	#mobile-navigation {

	}


	#teaser-top-teaser {
		top: 0;
	}
	#teaser-top-teaser.swiper-container-autoheight,
	#teaser-top-teaser.swiper-container-autoheight .swiper-slide {
		height: auto;
	}
	#teaser-top.home,
	#teaser-top.intro,
	#teaser-top.sub {
		height: 200px;
	}
	#teaser-top-teaser.home {
		position: relative;
		height: auto;
	}
	#teaser-top:after {
		display: none;

	}
	.slide-teaser,
	.slide-teaser.transparent,
	.slide-teaser.black {
		position: relative;
		top: auto;
		bottom: auto;
		left: auto;

		width: auto;

		background-color: $light-gray;

		padding-bottom: 30px;

	}
	#teaser-top-teaser.home .content-aligning {
		margin: 0;
		padding: 0;
	}
	.slide-teaser h4 {
		margin-top: 10px;
		margin-bottom: 20px;
		color: #e30613;
		font-size: 26px;
	}
	.slide-teaser p {
		color: $dark-gray;
	}

	.widget-5 .title {
		font-size: 24px;
	}
	.widget-4 .intro_textarea p {
		padding-right: 0;
	}

	#ref-filter ul.filter-list {
		float: none;
		width: 100%;
		margin-bottom: 1px;
	}

	.ref-item {
		width: 100%;
		margin-bottom: 15px;
	}

	.ref-recommend-content {
		margin: 0;
	}
	.ref-recommend-item {
		width: 100%;
		margin-left: 0;
	}

	.widget-1 > .widget-title {
		height: auto !important;
	}

	.widget-1 .widget-1-inner {
		height: auto !important;
	}

	.wpgmza_map {
		height: 400px !important;
	}

	/* ---------- Mobile Responsive Table ---------- */

	.main-content .widget-8 table,
	.main-content .widget-8 thead,
	.main-content .widget-8 tbody,
	.main-content .widget-8 tfoot,
	.main-content .widget-8 th,
	.main-content .widget-8 td,
	.main-content .widget-8 tr {
		display: block;
	}

	.main-content .widget-8 thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	.main-content .widget-8 tr {

	}

	.main-content .widget-8 tbody td {
		border: none;
		position: relative;
		padding-left: 6px;
		width: auto !important;
		text-align: left !important;
		border-bottom: 1px solid #fff;
		min-height: 20px !important;
		height: auto !important;
	}

	.main-content .widget-8 tbody td[data-title] {
		padding: 10px 0 10px 50%;
	}

	.main-content .widget-8 tbody td:first-child {
		background-color: transparent;
	}

	.main-content .widget-8 tbody td:before {
		position: relative;
		display: block;
		float: left;
		left: 6px;
		width: 100%;
		margin-left: -100%;
		padding-right: 10px;
		font-family: "BrixSansMedium", "Helvetica Neue", Arial, serif;
	}

	.main-content .widget-8 tbody td:after {
		position: relative;
		display: block;
		content: "";
		clear: both;
		width: 1px;
	}

	.main-content .widget-8 tbody td:before {
		content: attr(data-title);
	}

	.product-item-header h1 {
		font-size: 32px;
	}
	.product-item-image {
		width: 100%;
		margin: 3px 0 30px 0;
	}
	.product-item-summary {
		width: 100%;
		margin-bottom: 30px;
	}
	body.single .product-tags {
		position: relative;
		top: 0;
	}

	.filter ul.dropdown-list.category, .filter ul.dropdown-list.domain_contact, .filter ul.dropdown-list.domain_career {
		margin-right: 0;
		margin-bottom: 5px;
	}

	.filter ul.dropdown-list {
		width: 100%;
	}

	.accordion-tab {
		padding-right: 40px;
		line-height: 1.2;
	}

	.news-search-content .search-form {
		width: 100%;
		margin-bottom: 10px;
	}
	.news-search-content .advanced-search {
		width: 100%;
	}
	.news-search-content .search-form > label {
		width: 65%;
	}
	.news-search-content .search-form > .search-submit {
		width: 33%;
		margin-left: 2%;
	}

	.wpforms-container .wpforms-field .wpforms-field-label {
		width: 100%;
		margin-bottom: 10px;
	}
	.wpforms-container .wpforms-field-select .wpforms-field-large,
	.wpforms-container .wpforms-field .wpforms-field-large {
		width: 100%;
	}
	.wpforms-container .wpforms-field-description {
		margin-left: 0 !important;
	}
	.wpforms-recaptcha-container {
		width: 100%;
		margin-left: 0;
	}
	.wpforms-recaptcha-container .g-recaptcha > div {
		width: auto !important;
		max-width: 304px;
	}

	.widget-3 ul.featured-link-list li span {
		position: relative;
		left: 0;
	}
	.widget-3 ul.featured-link-list li .button {
		float: none;
		min-width: 0;
		margin-bottom: 10px;
	}

	.widget-1.bild_links .widget-image,
	.products .widget-1.bild_links .widget-image {
		width: 100%;
	}
	.widget-1.bild_links .widget-1-inner,
	.products .widget-1.bild_links .widget-1-inner {
		width: 100%;
		margin-left: 0;
	}

	.product-search-content {
		width: 100%;
	}
	.product-search-content ul.advanced-search-list.product_cat {
		margin-right: 0;
		margin-bottom: 5px;
	}
	.product-search-content ul.advanced-search-list {
		width: 100%;
	}

}


/**
 * 12.0 - Print
 */

@media print {

	table {
		border-collapse: collapse !important;
		border-spacing: 0 !important;
	}

	table tr td {
		background: none !important;
		border-left: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		padding: 10px !important;
	}

	body,
	#site-wrapper {
		display: block;
		height: auto !important;
	}

	#site-header {
		margin-bottom: 0 !important;
		height: auto !important;
		background-color: transparent;
	}

	#site-header a.logo {
		position: relative;
		height: auto;
		top: 0;
	}

	body.single .product-tags {
		top: -130px;
	}

	.main-content {
		width: 100% !important;
		float: none !important;
		padding-top: 0 !important;
		overflow: visible;
	}

	.site-content {
		margin-top: 0 !important;
	}

	.content-align {
		padding: 0 !important;
	}

	.swiper-container,
	.swiper-wrapper,
	.swiper-slide {
		display: block !important;
		height: auto !important;
		width: auto !important;
		transform: none !important;
	}

	.swiper-container {
		padding: 0 !important;
		float: none !important;
		overflow: visible !important;
	}

	.accordion-tab {
		padding: 0 !important;
		border-top: 0 none !important;
		cursor: default !important;
	}

	.accordion-tab .accordion-icon {
		display: none !important;
	}

	.accordion-body {
		max-height: none;
		opacity: 1;
	}

	form,
	button,
	input,
	select,
	textarea,
	.link,
	#site-topbar,
	#main-navigation,
	#toggle-mobile-menu,
	#header-large-dropdown,
	#teaser-top,
	.sidebar,
	.paginate,
	#site-bottom,
	#site-footer,
	#mobile-navigation,
	.tabs-button,
	.widget-5,
	.swiper-slide-duplicate,
	.product-item-backlink,
	.related.products,
	.filter,
	.ref-backlink,
	.ref-recommend,
	.swiper-pagination,
	.search-page-box {
		display: none !important;
	}

}

.alert-widget {
	padding: 1rem 1.75rem 1rem 1rem;
	background: $theme;
	margin-bottom: 1rem;
	color: #fff;

	h4 {
		margin-top: 0;
		margin-bottom: .25rem;
	}
}

.mb {
	margin-bottom: 1rem !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.form-widget {
	margin-bottom: 3rem;
}

.float-right {
	float: right;
}

.category-group {
	.category-row {
		h2 {
			margin-top: 0;
			margin-bottom: .25rem;
		}

		ul {
			list-style: none;
			margin-left: 0;
		}

		margin-bottom: 2.5rem;
	}
}

form textarea {
	min-height: 220px;
}
