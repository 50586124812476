@font-face {
  font-family: "BrixSansLight";
  src: url("../../app/fonts/brix/BrixSansLight.otf") format("opentype");
}

@font-face {
  font-family: "BrixSansMedium";
  src: url("../../app/fonts/brix/BrixSansMedium.otf") format("opentype");
}

@font-face {
  font-family: "BrixSansBlack";
  src: url("../../app/fonts/brix/BrixSansBlack.otf") format("opentype");
}

@import "genericons";
